import React from "react"

import "../styles/global.css"

import Navbar from "../components/Navbar"
import MobileNavbar from "../components/MobileNavbar"
import Footer from "../components/Footer"
import ContactFooter from "../components/ContactFooter"
import ServingIndustries from "../components/OurServicesPage/ServingIndustries"
import Carousel from "../components/OurServicesPage/Carousel"
import Whatsapp from "../components/Whatsapp"

import Seo from "../components/seo"

const HeaderText = () => {
  return (
    <div className="w-full flex flex-col justify-center items-center my-5">
      <div className="lg:w-3/4 w-10/12 flex flex-col justify-center items-center">
        <div className="2xl:text-4xl xl:text-3xl md:text-2xl text-xl  text-center font-helvetica font-bold text-text-primary uppercase my-6">
          we bring the best services to you
        </div>
        <div className="w-3/4 2xl:text-xl md:text-sm text-sm font-thin font-helvetica text-text-primary text-center">
          Ease your compliance burden without having to worry about deadlines as
          we support you with our team of experts
        </div>
      </div>
    </div>
  )
}

export default function OurServicesPage() {
  return (
    <>
      <Seo title="OUR SERVICES" />
      <Whatsapp />
      <div className="w-full flex flex-col justify-center items-center font-helvetica">
        <div className="md:hidden w-full flex flex-col justify-center items-center font-helvetica">
          <MobileNavbar>
            <HeaderText />
            <Carousel />
            <ServingIndustries />
            <Footer />
            <ContactFooter />
          </MobileNavbar>
        </div>
        <div className="md:visible md:w-full md:flex md:flex-col md:justify-center md:items-center md:font-helvetica hidden">
          <Navbar />
          <HeaderText />
          <Carousel />
          <ServingIndustries />
          <Footer />
          <ContactFooter />
        </div>
      </div>
    </>
  )
}
