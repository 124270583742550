import React from "react"

import { useStaticQuery, graphql } from "gatsby"

import { GatsbyImage, getImage } from "gatsby-plugin-image"

export default function PreviousPageSvg({ onClick, className }) {
  const data = useStaticQuery(graphql`
    {
      prevArrow: allFile(
        filter: {
          name: { eq: "previous-page" }
          extension: { eq: "png" }
          relativeDirectory: { eq: "ourServicesPage" }
        }
      ) {
        edges {
          node {
            childImageSharp {
              gatsbyImageData(pngOptions: { quality: 10 }, formats: PNG)
            }
          }
        }
      }
    }
  `)
  const prevArrow = data.prevArrow.edges[0].node.childImageSharp.gatsbyImageData
  return (
    <div onClick={onClick} className={className + " z-10"}>
      <GatsbyImage image={getImage(prevArrow)} className={"scale-[4.0]"} />
    </div>
  )
}
