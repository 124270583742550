import React, { useRef } from "react"

import "../../styles/global.css"

import { useStaticQuery, graphql } from "gatsby"

import BackgroundImage from "gatsby-background-image"
import { convertToBgImage } from "gbimage-bridge"
import { getImage } from "gatsby-plugin-image"
import "../../styles/global.css"

export default function ServingIndustries() {
  const data = useStaticQuery(graphql`
    {
      bgImage: allFile(
        filter: {
          relativeDirectory: { eq: "ourServicesPage" }
          name: { eq: "industries-bg" }
          extension: { eq: "png" }
        }
      ) {
        edges {
          node {
            childrenImageSharp {
              gatsbyImageData(formats: PNG)
            }
          }
        }
      }
    }
  `)

  var { bgImage } = data
  bgImage = bgImage.edges[0].node.childrenImageSharp[0].gatsbyImageData
  return (
    <div className="w-full flex flex-col justify-center items-center lg:my-0 my-10">
      <div
        id="industries"
        className="w-3/4 flex justify-center flex-col items-center text-center font-helvetica my-5"
      >
        <h1 className="2xl:text-3xl xl:text-2xl md:text-xl text-xl text-text-primary font-semibold uppercase mb-4">
          industries served
        </h1>
      </div>

      <div className="lg:w-3/5 w-full flex justify-center items-center lg:p-0 p-10">
        <BackgroundImage
          {...convertToBgImage(getImage(bgImage))}
          style={{ overflow: "hidden", borderRadius: "10px" }}
          className="w-full flex justify-center items-center"
        >
          <div className="w-full flex lg:flex-row flex-col lg:justify-evenly justify-center items-center p-10">
            <div className="lg:w-1/3 flex flex-col justify-center items-center my-2 ">
              <div className="text-industry lg:my-6 my-3">
                INFORMATION TECHNOLOGIES
              </div>
              <div className="text-industry lg:my-6 my-3 ">
                iron &amp; steel
              </div>
              <div className="text-industry lg:my-6 my-3">education</div>
              <div className="text-industry lg:my-6 my-3">
                retail &amp; wholesale
              </div>
            </div>
            <div className="lg:w-1/3 flex flex-col justify-center items-center my-2  mx-2">
              <div className="text-industry lg:my-6 my-3">automobile</div>
              <div className="text-industry lg:my-6 my-3">bpo's</div>
              <div className="text-industry lg:my-6 my-3">
                service providers
              </div>
              <div className="text-industry lg:my-6 my-3">medical</div>
            </div>
            <div className="lg:w-1/3 flex flex-col justify-center items-center my-2  mx-2">
              <div className="text-industry lg:my-6 my-3">real estate</div>
              <div className="text-industry lg:my-6 my-3">
                financial services
              </div>
              <div className="text-industry lg:my-6 my-3">fmcg</div>
              <div className="text-industry lg:my-6 my-3">professional</div>
            </div>
          </div>
        </BackgroundImage>
      </div>
    </div>
  )
}
