import React from "react"

import Slider from "react-slick"

import "../../styles/global.css"

import { useStaticQuery, graphql } from "gatsby"

import NextPageSvg from "../svg/NextPageSvg"
import PreviousPageSvg from "../svg/PreviousPageSvg"

import BackgroundImage from "gatsby-background-image"

import "slick-carousel/slick/slick-theme.css"
import "slick-carousel/slick/slick.css"

const Slide = ({ title, bgImage, content = "" }) => {
  const filterText = txt => {
    return String(txt).replace("_", " ").replace("_", " ")
  }

  return (
    <div className="w-full h-screen flex justify-center items-center py-5 sm:py-0">
      <div className="w-full h-5/6 flex justify-center items-center bg-card-border bg-opacity-60 rounded-xl lg:p-20 p-10 mx-5 sm:mx-10">
        <BackgroundImage
          fluid={bgImage}
          className={"w-full h-full flex justify-center items-center"}
          style={{
            overflow: "hidden",
            borderRadius: "10px",
          }}
        >
          <div className="w-full h-full flex lg:flex-row flex-col lg:justify-between justify-evenly items-start">
            <div className="lg:w-6/12 w-full md:h-full h-2/6 flex flex-col lg:justify-between justify-center items-start drop-shadow-lg ">
              <div className="w-full h-1/6 flex justify-center items-center slide lg:slider-text-gradient lg:py-0 py-16">
                <h1 className="md:text-2xl text-xl font-bold text-white  uppercase text-center my-5">
                  {filterText(title)}
                </h1>
              </div>
            </div>
            <div className="lg:w-6/12 w-full h-full flex justify-center items-start py-5 px-2 lg:px-8 slider-text-paragraph overflow-y-scroll">
              <p className="text-white font-lg text-left items-stretch my-2">
                {content}
              </p>
            </div>
          </div>
        </BackgroundImage>
      </div>
    </div>
  )
}

export default function Carousel() {
  const data = useStaticQuery(graphql`
    {
      slides: allFile(
        filter: {
          relativeDirectory: { eq: "ourServicesPage/services" }
          extension: { eq: "png" }
        }
      ) {
        edges {
          node {
            name
            childImageSharp {
              fluid(maxWidth: 953, maxHeight: 644, fit: COVER) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }

      content: allSite {
        edges {
          node {
            siteMetadata {
              carouselData {
                accounting
                audit_and_assurance
                financial_reporting
                payroll_processing
                tax_advisory
                vcfo
              }
            }
          }
        }
      }

      swipeArrow: allFile(
        filter: {
          relativeDirectory: { eq: "ourServicesPage" }
          extension: { eq: "svg" }
        }
      ) {
        edges {
          node {
            name
            publicURL
          }
        }
      }
    }
  `)

  var { slides, content, swipeArrow } = data
  slides = slides.edges
  content = content.edges[0].node.siteMetadata.carouselData
  const swipeArrowImages = swipeArrow.edges
  swipeArrow = {}
  swipeArrowImages.map(image => {
    swipeArrow[image.node.name.replace("-", "_")] = image.node.publicURL
  })

  const settings = {
    dots: false,
    customPaging: i => {
      return <div className="w-10 h-10 bg-red-700 rounded-full">{i}</div>
    },
    infinte: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextPageSvg />,
    prevArrow: <PreviousPageSvg />,
    lazyLoad: "progressive",

    responsive: [
      {
        breakpoint: "1024px",
        settings: {
          dots: false,
          infinte: true,
          speed: 300,
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          lazyLoad: "progressive",
          pauseOnHover: true,
        },
      },
    ],
  }
  return (
    <div className="w-full h-screen flex md:flex-row flex-col justify-center items-center overflow-hidden">
      <Slider
        {...settings}
        className="w-full xl:w-8/12 min-h-6 flex flex-row justify-center items-center"
        useCSS
        initialSlide={0}
      >
        {slides.map((slide, id) => {
          const { node } = slide
          return (
            <Slide
              index={id}
              title={node.name}
              bgImage={node.childImageSharp.fluid}
              content={content[node.name]}
            />
          )
        })}
      </Slider>
      <div className="md:hidden visible w-full flex justify-center items-start">
        <span className="w-full flex justify-center items-center">
          <span>
            <img src={swipeArrow.left_arrow} alt="left" className="mx-1" />
          </span>
          <span className="uppercase text-sm text-text-primary">swipe</span>
          <span>
            <img src={swipeArrow.right_arrow} alt="right" className="mx-1" />
          </span>
        </span>
      </div>
    </div>
  )
}
